import React from 'react';
import { Helmet } from 'react-helmet-async';
import { BlogContent, PageLayout } from '../components';
import { useTranslation } from 'react-i18next';

const BlogPage = () => {
  const { t } = useTranslation();

  const crumbsBlogPage = [
    {
      label: t('menu.main'),
      path: '/',
    },
    {
      label: t('menu.blog'),
    },
  ];

  return (
    <>
      <Helmet>
        <title>{t('titles.blog')} - Zennovaton - IT company</title>
        <meta name="description" content={t('aboutSection.text')} />
        <meta name="keywords" content="блог, статьи, новости" />
      </Helmet>
      <PageLayout>
        <BlogContent crumbs={crumbsBlogPage} />
      </PageLayout>
    </>
  );
};

export default BlogPage;
